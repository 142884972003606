import React from 'react'
import { useLocation } from 'react-router-dom';
function Dump() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
  
    // Pobierz wszystkie klucze i wartości parametrów
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return ( 
    <div>
      <h2>Parametry:</h2>
      <ul>
        {Object.entries(params).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {value}
          </li>
        ))}
      </ul>
    </div>
     );
}

export default Dump;